import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  Text14pxMedium,
  WalletBalanceText,
  WalletWarningText,
} from "../../CommonComponents";
import { CheckBoxComponent } from "../../ToggleComponents";
import TrestleButton from "../../TrestleButton";
import {
  BackwardArrow,
  DollarIcon,
  ForwardArrow,
  warningCircleIcon,
} from "../../../../assets/svgs";
import InputTextField from "../../InputTextField";
import {
  ActionButtonContainer,
  SelfServeBottomDiv,
  SelfServeTopDiv,
  WalletAmountDisplay,
} from "./Styles";
import {
  CreateCheckoutSession,
  UpdateAutoRecharge,
  CreateManageSubscriptionSession,
  GetWalletData,
} from "../Services/PaymentWalletServices";
import { RouteConstants } from "../../../../constants/RouteConstants";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToast } from "../../../../app/toastReducer";
import { AppConstants } from "../../../../constants/AppConstants";
import TrestleLoaderButton from "../../TrestleLoadingButton";
import { add, isEmpty } from "lodash";
import { setWallet } from "../../../../app/walletReducer";
import { getTimeDifference } from "../../../../utils/timeDifference";
import {
  SelfServeFormDataProps,
  SelfServeFormErrorProps,
  SelfServePlanComponentProps,
} from "../../../../constants/SelfServeInterfaces";
import {
  validateWalletAmount,
  validateWalletBalanceThreshold,
  validateWalletTargetBalance,
} from "../../../../utils/validations";
import { handleMixPanel } from "../../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../../constants/MixPanelEvents";
import { useAppSelector } from "../../../../app/hooks";

const SelfServePlanComponent = ({
  walletData,
  setAnchorEl,
}: // getWalletData,
SelfServePlanComponentProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addToWallet, setAddToWallet] = useState<boolean>(false);
  const [changeAutoReFill, setChangeAutoReFill] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdateAutoRechargeLoading, setisUpdateAutoRechargeLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<SelfServeFormDataProps>({
    amount: null,
    autoRechargeEnabled: false,
    balanceThreshold: null,
    targetBalance: null,
  });

  const [formError, setFormError] = useState<SelfServeFormErrorProps>({
    amount: "",
    autoRechargeEnabled: "",
    balanceThreshold: "",
    targetBalance: "",
  });

  const [disableWalletValue, setDisableWalletValue] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      ...formData,
      autoRechargeEnabled: walletData?.autoRechargeEnabled,
    });
    if (parseFloat(walletData?.balance as any) < 0) {
      setFormError({
        ...formError,
        amount: `Please input a value greater than $${parseFloat(
          (10 - parseFloat(walletData?.balance as any)) as any
        ).toFixed(2)} to achieve the minimum recommended wallet balance.`,
      });
      setDisabled(true);
    }
  }, []);

  useEffect(() => {
    isDisabled();
  }, [formData]);

  const getWarningText = () => {
    if (walletData?.autoRechargeEnabled && !walletData?.autoRechargeSuccess) {
      return <Text14pxMedium>Current Balance</Text14pxMedium>;
    } else if (
      (walletData?.balance &&
        walletData?.balanceThreshold &&
        parseFloat(walletData?.balance as any) <
          parseFloat(walletData?.balanceThreshold as any)) ||
      parseFloat(walletData?.balance as any) < 10.0
    ) {
      return <WalletWarningText>Account Balance Low</WalletWarningText>;
    } else if (
      walletData &&
      walletData?.walletDisplayType === "TRIAL_EXPIRED"
    ) {
      return <WalletWarningText>Your Trial Plan is Expired</WalletWarningText>;
    } else {
      return <Text14pxMedium>Current Balance</Text14pxMedium>;
    }
  };

  const handleAddToWalletClick = () => {
    setAddToWallet(true);
    setFormData({
      amount: "",
      autoRechargeEnabled: walletData?.autoRechargeEnabled,
      balanceThreshold: walletData?.balanceThreshold,
      targetBalance: walletData?.targetBalance,
    });
    if (parseFloat(walletData?.balance as any) < 0) {
      setFormError({
        ...formError,
        amount: `Please input a value greater than $${parseFloat(
          (10 - parseFloat(walletData?.balance as any)) as any
        ).toFixed(2)} to achieve the minimum recommended wallet balance.`,
      });
      setDisabled(true);
    } else {
      setFormError({
        amount: "",
        autoRechargeEnabled: "",
        balanceThreshold: "",
        targetBalance: "",
      });
    }
  };

  const handleChangeClick = () => {
    setAddToWallet(true);
    setDisableWalletValue(true);
    setChangeAutoReFill(true);
    setFormData({
      amount: "",
      autoRechargeEnabled: walletData?.autoRechargeEnabled,
      balanceThreshold: walletData?.balanceThreshold,
      targetBalance: walletData?.targetBalance,
    });
    setFormError({
      amount: "",
      autoRechargeEnabled: "",
      balanceThreshold: "",
      targetBalance: "",
    });
  };

  const [disabled, setDisabled] = useState<boolean>(false);

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const handleCheckOutClick = async () => {
    setIsLoading(true);

    if (!validateField(formData, walletData)) {
      setIsLoading(false);
      return;
    }
    if (formData.targetBalance && formData.balanceThreshold) {
      setFormData({
        ...formData,
        autoRechargeEnabled: true,
      });
      handleMixPanel(MixPanelEvents.WALLET_AUTOMATIC_REFILL,
      {userEmail: userData?.email,
       walletRefillThreshold: formData.balanceThreshold,
       WalletTargetBalance: formData.targetBalance 
      });
    }
    const payload = {
      amount: formData.amount,
      targetBalance: formData.targetBalance,
      balanceThreshold: formData.balanceThreshold,
      isAutoRechargeEnabled:
        formData.targetBalance && formData.balanceThreshold ? true : false,
    };
    const response = await CreateCheckoutSession(payload);
    const { statusCode = 401, data = {} } = response;
    if (statusCode === 200) {
      window.dataLayer.push({  // Added GMT tag for checkout session start
        event: 'begin_checkout',
        userId: userData?.userId,
        ecommerce: {
          currency: 'USD',
          items: [{
            item_name: 'api credit',
            item_category: formData.balanceThreshold? formData.balanceThreshold : '',
            item_category1: formData.targetBalance? formData.targetBalance : '',
            price: formData.amount
          }]
        }
      });

      window.open(data, "_self");
      handleMixPanel(MixPanelEvents.WALLET_CHECKOUT_SESSION,
      {userEmail: userData?.email,
       amountCheckedOut: formData.amount 
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isDisabled();
  }, [formData, formError]);

  const handleChange = (e?: any) => {
    const { name = "", value = "", label = "" } = e?.target;
    const reg = /^[0-9]*$/;
    if (!reg.test(value) || value === " ") {
      return;
    }
    if (name === "targetBalance" || name === "balanceThreshold") {
      setFormError({
        ...formError,
        targetBalance: "",
        balanceThreshold: "",
      });
    } else {
      setFormError({
        ...formError,
        [name]: "",
      });
    }
    setFormData({ ...formData, [name]: value });
  };

  const validateField = (formData: SelfServeFormDataProps, walletData: any) => {
    let isValid = true;
    const temError: SelfServeFormErrorProps = {
      amount: addToWallet ? validateWalletAmount(formData, walletData) : "",
      balanceThreshold: validateWalletBalanceThreshold(formData),
      targetBalance: validateWalletTargetBalance(formData),
      autoRechargeEnabled: "",
    };
    if (
      temError.amount !== "" ||
      temError.balanceThreshold !== "" ||
      temError.targetBalance !== ""
    ) {
      setFormError(temError);
      isValid = false;

      handleMixPanel(MixPanelEvents.WALLET_VALIDATION_ERRORS,
        {userEmail: userData?.email,
         error: temError
        });
    }

    return isValid;
  };

  const isDisabled = () => {
    if (
      isEmpty(formData.amount) ||
      (formData.autoRechargeEnabled === true &&
        (!formData.balanceThreshold || !formData.targetBalance))
    ) {
      return true;
    } else if (
      formError.amount ||
      formError.balanceThreshold ||
      formError.targetBalance
    ) {
      return true;
    } else {
      return false;
    }
  };

  const updateAutoRecharge = async () => {
    setisUpdateAutoRechargeLoading(true);

    if (!validateField(formData, walletData)) {
      setisUpdateAutoRechargeLoading(false);
      return;
    }
    
    const isAutoRechargeEnabled = !!(formData?.balanceThreshold && formData?.targetBalance);
    setFormData(prevFormData => ({
      ...prevFormData,
      autoRechargeEnabled: isAutoRechargeEnabled
    }));

    if (formData.targetBalance && formData.balanceThreshold) {
      handleMixPanel(MixPanelEvents.WALLET_AUTOMATIC_REFILL,
      {userEmail: userData?.email,
       walletRefillThreshold: formData.balanceThreshold,
       WalletTargetBalance: formData.targetBalance 
      });
    }
  
    const payload = {
      isAutoRechargeEnabled,
      balanceThreshold: parseInt(formData?.balanceThreshold as string),
      targetBalance: parseInt(formData?.targetBalance as string),
    };

    const response = await UpdateAutoRecharge(payload);
    const { statusCode = 401, data = {} } = response;
    if (statusCode === 200) {
      await GetWalletData().then((res) => {
        dispatch(setWallet(res.data));
        setChangeAutoReFill(false);
        setAddToWallet(false);
        setDisableWalletValue(false);
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: response?.message,
          })
        );
        setisUpdateAutoRechargeLoading(false);
      });
    } else {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: response?.message,
        })
      );
      handleMixPanel(MixPanelEvents.WALLET_ERROR_FOR_UPDATE_WALLET_DATA_API, {
        userEmail: userData?.email,
        error: response.message
      });
      setisUpdateAutoRechargeLoading(false);
    }
  };

  const manageSubscriptionApiCall = async () => {
    setIsLoading(true);
    const response = await CreateManageSubscriptionSession();
    const { statusCode = 401, data = {} } = response;
    if (statusCode === 200) {
      window.open(data, "_self");
    }
  };

  return (
    <Box>
      <SelfServeTopDiv>
        {addToWallet ? (
          <>
            <WalletBalanceText>Add To Wallet</WalletBalanceText>
          </>
        ) : (
          <>
            {getWarningText()}
            <WalletBalanceText>{`${
              walletData?.balance
                ? parseFloat(walletData.balance as any) < 0
                  ? `-$${-parseFloat(walletData.balance as any).toFixed(2)}`
                  : `$${parseFloat(walletData.balance as any).toFixed(2)}`
                : 0
            }`}</WalletBalanceText>
            <Text14pxMedium>
              Last updated {getTimeDifference(walletData.lastUpdatedDate)} ago
            </Text14pxMedium>
          </>
        )}
      </SelfServeTopDiv>
      <SelfServeBottomDiv>
        {!walletData?.autoRechargeSuccess && walletData?.autoRechargeEnabled ? (
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                flexDirection: "column",
                mb: "20px",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                {warningCircleIcon}{" "}
                <WalletWarningText>
                  Automatic refill payment failed.
                </WalletWarningText>
              </Box>
              <Text14pxMedium style={{ marginLeft: "25px" }}>
                Please update payment info to continue automatic refills.
              </Text14pxMedium>
            </Box>
            <TrestleLoaderButton
              onClick={manageSubscriptionApiCall}
              label="Update Payment Info"
              type="primary"
              className="!h-[40px]"
              loading={isLoading}
            />
          </Box>
        ) : (
          <>
            {addToWallet && (
              <Box
                sx={{
                  display: "flex",
                  gap: "30px",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <ActionButtonContainer
                  onClick={() => {
                    setAddToWallet(false);
                    setChangeAutoReFill(false);
                    setDisableWalletValue(false);
                    setFormData({
                      amount: "",
                      autoRechargeEnabled: walletData?.autoRechargeEnabled,
                      balanceThreshold: walletData?.balanceThreshold,
                      targetBalance: walletData?.targetBalance,
                    });
                    setFormError({
                      amount: "",
                      autoRechargeEnabled: "",
                      balanceThreshold: "",
                      targetBalance: "",
                    });
                    setDisabled(false);
                    handleMixPanel(MixPanelEvents.WALLET_BACK_BUTTON, {userEmail: userData?.email});
                  }}
                >
                  {BackwardArrow}{" "}
                  <Text14pxMedium c={"#0494B4"}>Back</Text14pxMedium>
                </ActionButtonContainer>
                <InputTextField
                  label="Wallet Value to Add"
                  name="amount"
                  onChange={handleChange}
                  placeholder="Enter a value between $25–250"
                  value={formData}
                  error={formError}
                  searchable={true}
                  startIcon={DollarIcon}
                  height="48px"
                  disabled={disableWalletValue}
                  showErrorInBottom={true}
                  disabledStyle={"!bg-[#C8CFD2]"}
                  startAdornmentStyle={`!ml-0 !max-h-[100%] !w-[48px] bg-[#E5E5E5] !h-[100%] justify-center items-center ${
                    disableWalletValue ? "!bg-[#BCC2C5]" : ""
                  }`}
                  className="!px-0"
                />
              </Box>
            )}
            {(addToWallet || changeAutoReFill) && (
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <CheckBoxComponent
                  value={formData.autoRechargeEnabled}
                  toggleCheck={() => {
                    setFormData({
                      ...formData,
                      targetBalance: "",
                      balanceThreshold: "",
                      autoRechargeEnabled: !formData.autoRechargeEnabled,
                    });
                    setFormError({
                      ...formError,
                      targetBalance: "",
                      balanceThreshold: "",
                    });
                    isDisabled();
                  }}
                  disabled={!addToWallet}
                  className="w-[28px] h-[28px]"
                />
                <Text14pxMedium c="#163545">
                  Automatically refill wallet when it goes below a threshold.
                </Text14pxMedium>
              </Box>
            )}
            {formData?.autoRechargeEnabled && !addToWallet && (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <WalletAmountDisplay>
                  <Text14pxMedium>
                    {`Refill Threshold: $${
                      walletData?.balanceThreshold
                        ? walletData?.balanceThreshold
                        : 0
                    }`}
                  </Text14pxMedium>
                    <ActionButtonContainer
                      u={"underline"}
                      onClick={() => {
                        handleChangeClick()
                        handleMixPanel(MixPanelEvents.WALLET_CHANGE_BUTTON, {userEmail: userData?.email})
                      }}
                    >
                    Change
                  </ActionButtonContainer>
                </WalletAmountDisplay>
                <WalletAmountDisplay>
                  <Text14pxMedium>
                    {`Refill Amount: $${
                      walletData?.targetBalance ? walletData?.targetBalance : 0
                    }`}
                  </Text14pxMedium>
                  <ActionButtonContainer
                    u={"underline"}
                    onClick={() => {
                      handleChangeClick()
                      handleMixPanel(MixPanelEvents.WALLET_CHANGE_BUTTON, {userEmail: userData?.email})
                    }}
                  >
                    Change
                  </ActionButtonContainer>
                </WalletAmountDisplay>
              </Box>
            )}
            {addToWallet && (
              <>
                <InputTextField
                  label="Wallet Refill Threshold"
                  name="balanceThreshold"
                  onChange={handleChange}
                  placeholder="Enter a value between $10–250"
                  value={formData}
                  error={formError}
                  searchable={true}
                  startIcon={DollarIcon}
                  height="48px"
                  showErrorInBottom={true}
                  startAdornmentStyle="!ml-0 !max-h-[100%] !w-[48px] !bg-[#E5E5E5] !h-[100%] justify-center items-center"
                  className="!px-0"
                />
                <InputTextField
                  label="Refill Wallet To"
                  name="targetBalance"
                  onChange={handleChange}
                  placeholder="Enter a value between $25–250"
                  value={formData}
                  error={formError}
                  searchable={true}
                  startIcon={DollarIcon}
                  height="48px"
                  showErrorInBottom={true}
                  startAdornmentStyle="!ml-0 !max-h-[100%] !w-[48px] !bg-[#E5E5E5] !h-[100%] justify-center items-center"
                  className="!px-0"
                />
              </>
            )}
            {addToWallet ? (
              <>
                {changeAutoReFill ? (
                  <TrestleLoaderButton
                    onClick={updateAutoRecharge}
                    label="Update Auto-refill"
                    type="primary"
                    className="!h-[60px]"
                    disabled={
                      disabled ||
                      (formData.autoRechargeEnabled === true &&
                        (!formData.balanceThreshold || !formData.targetBalance))
                    }
                    loading={isUpdateAutoRechargeLoading}
                  />
                ) : (
                  <>
                    <TrestleLoaderButton
                      onClick={() => {
                        handleCheckOutClick();
                        handleMixPanel(MixPanelEvents.WALLET_CHECKOUT_BUTTON,
                            {userEmail: userData?.email,
                            walletValueToAdd: formData.amount,
                            walletRefillThreshold: formData.balanceThreshold,
                            walletTargetBalance: formData.targetBalance
                            });
                      }}
                      label="Go To Checkout"
                      type="primary"
                      className="!h-[60px]"
                      icon={ForwardArrow}
                      disabled={isDisabled() || !walletData.topUpEligible}
                      loading={isLoading}
                    />
                    <TrestleLoaderButton
                      onClick={updateAutoRecharge}
                      label="Update Auto-refill"
                      type="primary"
                      className="!h-[60px]"
                      disabled={
                        disabled ||
                        (formData.autoRechargeEnabled === true &&
                          (!formData.balanceThreshold || !formData.targetBalance))
                      }
                      loading={isUpdateAutoRechargeLoading}
                    />
                    {!walletData.topUpEligible && (
                      <Text14pxMedium style={{ color: '#b23352'}}>
                        Maximum Wallet Charges processed this month, please reach out to support@trestleiq.com
                      </Text14pxMedium>
                    )}
                  </>
                )}
              </>
            ) : (
              <Box sx={{ display: "flex", gap: "10px" }}>
                <TrestleButton
                  onClick={ () => {
                    handleAddToWalletClick();
                    handleMixPanel(MixPanelEvents.WALLET_ADD_TO_WALLET_BUTTON, {userEmail: userData?.email});
                  }
                  }
                  label="Add To Wallet"
                  type="primary"
                  className="!min-w-[145px] !h-[40px]"
                />
                <TrestleButton
                  onClick={() => {
                    navigate(`${RouteConstants.ROUTE_PRICING_PAGE}`);
                    setAnchorEl(null);
                    handleMixPanel(MixPanelEvents.WALLET_UPGRADE_PLAN_BUTTON, {userEmail: userData?.email});
                  }}
                  label="Upgrade Plan"
                  type="secondry"
                  className="!min-w-[145px] !h-[40px]"
                />
              </Box>
            )}
          </>
        )}
      </SelfServeBottomDiv>
    </Box>
  );
};

export default SelfServePlanComponent;
