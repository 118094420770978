import { useEffect, useState } from "react";
import DialogPopup from "../../Shared/DialogPopup";
import Select from "react-select";
import { Box, Tooltip } from "@mui/material";
import TrestleButton from "../../Shared/TrestleButton";
import { submitBatch } from "./BatchUploadService";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { WalletInputErrortext } from "../../Shared/InputTextField";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import { ActionButtonContainer } from "../../Shared/PaymentWallet/WalletComponets/Styles";
import {
  Text16pxBold,
  Text16pxMedium,
  Text20pxMedium,
} from "../../Shared/CommonComponents";

interface ApiKeyProduct {
  displayName: string;
  productName: string;
}

export const InfoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-5 h-5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
    />
  </svg>
);

interface BatchUploadPopUpProps {
  open: boolean;
  setOpen: any;
  setIsLoading?: any;
  apiKeysAndProducts: Record<string, ApiKeyProduct>;
  callGetBatchAPI?: any;
}

const BatchUploadPopUp = ({
  open,
  setOpen,
  setIsLoading,
  apiKeysAndProducts,
  callGetBatchAPI,
}: BatchUploadPopUpProps) => {
  const dispatch = useDispatch();

  const [selectedKey, setSelectedKey] = useState<any>();

  const [selectedProduct, setSelectedProduct] = useState<any>();

  const [apiKeyList, setApiKeyList] = useState<any>([]);

  const [productList, setProductList] = useState<any>([]);

  const [errorMessage, setErrorMessage] = useState<any>(null);

  const [file, setFile] = useState<any>(null);

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  useEffect(() => {
    const apiKeys = Object.entries(apiKeysAndProducts).map(([k, v]) => ({
      label: k,
      value: v,
      id: k,
    }));
    setErrorMessage(null);
    setSelectedKey(null);
    setSelectedProduct(null);

    setApiKeyList(apiKeys);
  }, [apiKeysAndProducts, open]);

  useEffect(() => {
    if (
      file != undefined &&
      selectedKey != undefined &&
      selectedProduct != undefined
    )
      setDisableSubmit(false);
  }, [selectedKey, selectedProduct, file]);

  const handleChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "text/csv") {
        setFile(file);
      }
    }
  };

  const handleApiKeySelect = (data: any) => {
    setSelectedKey(data);
    const products = data.value.map((v: any) => ({
      label: v.displayName,
      value: v.apiName,
      id: v.apiName,
    }));
    setProductList(products);
  };

  const handleProductSelect = (data: any) => {
    setSelectedProduct(data);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setErrorMessage(null);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("apiKey", selectedKey.label);
    formData.append("apiName", selectedProduct.value);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    setIsLoading(true);
    const response = await submitBatch(formData);
    setIsLoading(false);

    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      if (
        data.data ===
        "The file has been uploaded successfully and is now being processed."
      ) {
        setOpen(false);
        setSelectedProduct(null);
        setSelectedKey(null);
        setFile(null);
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: data.data,
          })
        );
        callGetBatchAPI();
      } else {
        setErrorMessage(data.data);
      }
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  return (
    <DialogPopup
      open={open}
      applyZIndex={false}
      onClose={(val: any) => {
        setOpen(false);
        setErrorMessage(null);
        setSelectedKey(null);
        setSelectedProduct(null);
      }}
      closeButton={true}
      title={""}
      className="!p-0"
      component={
        <Box className="pb-[50px] w-[700px]">
          {/* header */}
          <Box className="w-full py-8 px-[20px] bg-[#F3F5F6] text-[#163545] mb-6">
            <Box className="text-xl font-bold flex">Batch Upload</Box>
          </Box>
          {/* header */}

          <Box className="px-[20px] flex flex-col gap-[32px]">
            <Box>
              {errorMessage && (
                <WalletInputErrortext>{errorMessage}</WalletInputErrortext>
              )}
            </Box>
            <Box id="step1" className="flex flex-col gap-[8px]">
              <Text16pxBold className="!font-bold">Step-1</Text16pxBold>
              <Box className="flex justify-between items-center">
                <Text16pxMedium>Choose API Key</Text16pxMedium>
                <Box className="flex w-[70%] items-center">
                  <Box className="w-[100%] mr-2">
                    <Select
                      options={apiKeyList}
                      placeholder="Select API Key"
                      value={selectedKey}
                      onChange={handleApiKeySelect}
                      className="select-dropdown"
                    />
                  </Box>
                  <Tooltip
                    title={"Only available API keys"}
                    arrow
                    sx={{
                      width: "auto",
                      zIndex: 111112,
                      height: "fit-content",
                    }}
                  >
                    <span className="h-fit">{InfoIcon}</span>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Box id="step2" className="flex flex-col gap-[8px]">
              <Text16pxBold className="!font-bold">Step-2</Text16pxBold>
              <Box className="flex justify-between items-center">
                <Text16pxMedium>Choose Product</Text16pxMedium>
                <Box className="flex w-[70%] items-center">
                  <Box className="w-[100%] mr-2">
                    <Select
                      options={productList}
                      placeholder="Select Product"
                      value={selectedProduct}
                      onChange={handleProductSelect}
                      className="select-dropdown"
                    />
                  </Box>
                  <Tooltip
                    title={
                      "Only products you have access to will be available for Batch Upload"
                    }
                    arrow
                    sx={{
                      width: "100px",
                      zIndex: 111112,
                      height: "fit-content",
                    }}
                  >
                    <span className="h-fit">{InfoIcon}</span>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Box id="step3" className="flex flex-col gap-[8px]">
              <Text16pxBold className="!font-bold">Step-3</Text16pxBold>
              <Box className="flex justify-between">
                <Text16pxMedium>Upload CSV</Text16pxMedium>
                <Box className="flex flex-col gap-[10px] w-[70%]">
                  <input
                    type="file"
                    name="upload-csv"
                    id="upload-csv-test"
                    // key={inputKey}
                    multiple={false}
                    accept="text/csv"
                    onChange={handleChange}
                  />
                  <ActionButtonContainer
                    u={"underline"}
                    onClick={() =>
                      window.open(
                        "https://trestleiq.com/knowledge-base/batch-upload-instructions/"
                      )
                    }
                  >
                    Input File Template and Instructions
                  </ActionButtonContainer>
                </Box>
              </Box>
            </Box>
            <Box id="step4" className="flex w-[70%] ml-auto">
              <TrestleButton
                onClick={handleSubmit}
                label="Submit"
                disabled={disableSubmit}
                type="primary"
                className="max-w-[180px] !h-[40px] !py-0 !pt-0 mr-auto"
              />
              <TrestleButton
                onClick={() => {
                  setOpen(false);
                }}
                label="Cancel"
                type="secondry"
                className="max-w-[180px] !h-[40px] !py-0 !pt-0 ml-3 mr-auto"
              />
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default BatchUploadPopUp;
