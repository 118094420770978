import { Box, Divider, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BackwardArrow,
  CheckIcon,
  ClockIcon,
  LinkIcon,
  TrestleIconSmall,
} from "../../../assets/svgs";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { AppConstants } from "../../../constants/AppConstants";
import {
  APIKEY_LABEL,
  BannerComponentDiv,
  ComponentLabelHeader,
  Text14pxBold,
  Text16px,
  Text16pxBold,
  Text16pxMedium,
  Text20pxBold,
} from "../../Shared/CommonComponents";
import { setCurrentApiKey } from "../../../app/apiKeyReducer";
import PhoneLogo from "../../../assets/Phone-Logo.svg";
import DropDown from "../../Shared/DropDown";
import { ActionButtonContainer } from "../../Shared/PaymentWallet/WalletComponets/Styles";
import NewTestRequestForm from "../../Shared/NewTestRequestFormComponent";
import JsonDisplayComponent from "../../Shared/JsonDisplayComponent";
import LoaderComponent from "../../Shared/LoaderComponent";
import ApiProductResponseKeyListComponent from "../../Shared/ApiProductResponseKeyList";
import TrestleButton from "../../Shared/TrestleButton";
import { setToast } from "../../../app/toastReducer";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { requestProductAccess } from "../../../http/authentication-service";
import * as _ from "lodash";
import { RouteConstants } from "../../../constants/RouteConstants";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { expiryDate } from "../../../utils/helpers";
import YourUsage from "../YourUsage/YourUsage";
import InputTextField from "../../Shared/InputTextField";

const ApiKeyProductDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentApiProduct = useAppSelector(
    (state) => state.apiKeyReducer.currentApiProduct
  );
  const currentApiKey = useAppSelector(
    (state) => state.apiKeyReducer.currentApiKey as any
  );
  const walletData = useAppSelector((state) => state.walletReducer.walletData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [version, setVersion] = useState<string>("");
  const [currentSelectedProduct, setCurrentSelectedProduct] =
    useState<any>(null);
  const [versionArray, setVersionArray] = useState<string[]>([]);
  const [availableVersions, setAvailableVersions] = useState<any>();
  const [response, setResponse] = useState<any>();
  const [status, setStatus] = useState<string>("Ready");
  const [expanded, setExpanded] = useState<string>("");
  const [apiKeyResponseDetails, setApiKeyResponseDetails] = useState<any>();
  const [requestUrl, setResponseUrl] = useState<string>("");

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const productsData: any = {
    "Phone Validation API": "0.015",
    "Real Contact API": "0.03",
    "Reverse Phone API": "0.07",
    "Reverse Address API": "0.07",
    "Find Person API": "0.07",
    "Caller Identification API": "0.07",
    "Smart CNAM API": "0.015",
  };

  useEffect(() => {
    const { tempAvailableVersions, versionArray } = getApiProductVersions(
      currentApiKey.products,
      currentApiProduct
    );
    setCurrentSelectedProduct(
      tempAvailableVersions ? tempAvailableVersions[0] : []
    );
    setVersionArray(versionArray as string[]);
    setAvailableVersions(tempAvailableVersions);
    setResponseKeyDetails();
    setExpanded(`panel${0}`);
  }, [currentApiProduct]);

  useEffect(() => {
    setInitialResponseData();
    setStatus("Ready");
    getApiProductRequestUrl();
  }, [currentApiProduct, currentSelectedProduct, version]);

  // Function to extract the version from the `apiName` and return it as a float
  const extractVersion = (apiName: string): number => {
    const versionMatch = apiName.match(/(\d+(\.\d+)?)/);
    return versionMatch ? parseFloat(versionMatch[0]) : 0;
  };

  // Function to get the latest version and list all versions for a product
  const getApiProductVersions = (products: any[], savedProductName: string) => {
    // Filter products with the saved product name
    const filteredProducts = products.filter(
      (product) => product.productName === savedProductName
    );

    // If no product found, return an empty array
    if (filteredProducts?.length === 0) {
      return { currentProduct: null, availableVersions: [] };
    }

    // Sort the products by version number (as float) in descending order
    const sortedProducts = filteredProducts.sort(
      (a, b) => extractVersion(b.apiName) - extractVersion(a.apiName)
    );

    // Set the current product as the one with the latest version
    const currentProduct = sortedProducts[0];
    const latestVersion = extractVersion(currentProduct.apiName);

    // List all versions, format as "Version X.X" and append "(latest)" to the latest one
    const tempAvailableVersions = sortedProducts.map((product) => {
      const version = extractVersion(product.apiName);
      const versionLabel = `Version ${version.toFixed(1)}${
        version === latestVersion ? " (latest)" : ""
      }`;

      return {
        subscriptionId: currentApiKey.subscriptionId,
        productName: product.productName,
        pricing: productsData[product.productName],
        productId: product.productId,
        usageCountV2: product.usageCountV2,
        usagePlanId: product.usagePlanId,
        productRank: product.productRank,
        apiName: product.apiName,
        version: version,
        status: product.status,
        versionLabel: versionLabel, // Formatted version label
      };
    });

    const versionArray = tempAvailableVersions.map(
      (product) => product.versionLabel
    );

    versionArray.map((item) => {
      if (item.includes("(latest)")) {
        setVersion(item);
      }
    });

    return { currentProduct, tempAvailableVersions, versionArray };
  };

  const handleVersionChange = (version: string) => {
    setVersion(version);
    setCurrentSelectedProduct(
      availableVersions.find((item: any) => item.versionLabel === version)
    );
  };

  const setInitialResponseData = () => {
    switch (currentSelectedProduct?.apiName) {
      case "Caller Identification 3.1":
        setResponse(
          AppConstants.DUMMY_INITIAL_API_RESPONSE["Caller Identification 3.1"]
        );
        break;
      case "Caller Identification 3.0":
        setResponse(
          AppConstants.DUMMY_INITIAL_API_RESPONSE["Caller Identification 3.0"]
        );
        break;
      case "Phone Intel 3.0":
        setResponse(AppConstants.DUMMY_INITIAL_API_RESPONSE["Phone Intel 3.0"]);
        break;
      case "Real Contact 1.1":
        setResponse(
          AppConstants.DUMMY_INITIAL_API_RESPONSE["Real Contact 1.1"]
        );
        break;
      case "Real Contact 1.0":
        setResponse(
          AppConstants.DUMMY_INITIAL_API_RESPONSE["Real Contact 1.0"]
        );
        break;
      case "Reverse Phone 3.2":
        setResponse(
          AppConstants.DUMMY_INITIAL_API_RESPONSE["Reverse Phone 3.2"]
        );
        break;
      case "Reverse Phone 3.1":
        setResponse(
          AppConstants.DUMMY_INITIAL_API_RESPONSE["Reverse Phone 3.1"]
        );
        break;
      case "Reverse Phone 3.0":
        setResponse(
          AppConstants.DUMMY_INITIAL_API_RESPONSE["Reverse Phone 3.0"]
        );
        break;
      case "Smart CNAM 3.1":
        setResponse(AppConstants.DUMMY_INITIAL_API_RESPONSE["Smart CNAM 3.1"]);
        break;
      case "Phone Data 1.0":
        setResponse(AppConstants.DUMMY_INITIAL_API_RESPONSE["Phone Data 1.0"]);
        break;
      case "Find Person 3.1":
        setResponse(AppConstants.DUMMY_INITIAL_API_RESPONSE["Find Person 3.1"]);
        break;
      case "Find Person 3.0":
        setResponse(AppConstants.DUMMY_INITIAL_API_RESPONSE["Find Person 3.0"]);
        break;
      case "Reverse Address 3.1":
        setResponse(
          AppConstants.DUMMY_INITIAL_API_RESPONSE["Reverse Address 3.1"]
        );
        break;
      case "Reverse Address 3.0":
        setResponse(
          AppConstants.DUMMY_INITIAL_API_RESPONSE["Reverse Address 3.0"]
        );
        break;
      case "Location Intel 3.0":
        setResponse(
          AppConstants.DUMMY_INITIAL_API_RESPONSE["Location Intel 3.0"]
        );
        break;
    }
  };

  const setResponseKeyDetails = () => {
    switch (currentApiProduct) {
      case "Reverse Phone API":
        setApiKeyResponseDetails(
          AppConstants.API_PRODUCT_RESPONSE_KEYS_DETAILS["Reverse Phone API"]
        );
        break;
      case "Caller Identification API":
        setApiKeyResponseDetails(
          AppConstants.API_PRODUCT_RESPONSE_KEYS_DETAILS[
            "Caller Identification API"
          ]
        );
        break;
      case "Smart CNAM API":
        setApiKeyResponseDetails(
          AppConstants.API_PRODUCT_RESPONSE_KEYS_DETAILS["Smart CNAM API"]
        );
        break;
      case "Real Contact API":
        setApiKeyResponseDetails(
          AppConstants.API_PRODUCT_RESPONSE_KEYS_DETAILS["Real Contact API"]
        );
        break;
      case "Phone Validation API":
        setApiKeyResponseDetails(
          AppConstants.API_PRODUCT_RESPONSE_KEYS_DETAILS["Phone Validation API"]
        );
        break;
      case "Find Person API":
        setApiKeyResponseDetails(
          AppConstants.API_PRODUCT_RESPONSE_KEYS_DETAILS["Find Person API"]
        );
        break;
      case "Reverse Address API":
        setApiKeyResponseDetails(
          AppConstants.API_PRODUCT_RESPONSE_KEYS_DETAILS["Reverse Address API"]
        );
        break;
      case "Address Validation API":
        setApiKeyResponseDetails([]);
        break;
      default:
        break;
    }
  };

  const RequestAccess = async (productData: any) => {
    setIsLoading(true);
    const { subscriptionId, usagePlanId } = productData;

    let payload = {
      subscriptionId,
      usagePlanId,
    };
    const response = await requestProductAccess(payload);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK || status === HTTP_STATUS.HTTP_CREATED) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          heading: "Access request sent!",
          msg: "You should receive access in the next few hours.",
          tostIcon: CheckIcon,
        })
      );
      setCurrentSelectedProduct({
        ...currentSelectedProduct,
        status: "REQUESTED",
      });
      dispatch(
        setCurrentApiKey({
          ...currentApiKey,
          products: currentApiKey?.products.map((product: any) =>
            product.apiName === productData.apiName
              ? { ...product, status: "REQUESTED" }
              : product
          ),
        })
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const getApiProductRequestUrl = () => {
    setResponseUrl(
      AppConstants?.API_PRODUCT_REQUEST_URLS[currentSelectedProduct?.apiName]
    );
  };

  const getBannerComponentData = () => {
    if (
      currentApiKey?.planType === "TRIAL" &&
      currentSelectedProduct?.status != "CAN_REQUEST"
    ) {
      return (
        <div className="w-[100%] bg-[#E5F4F7] items-center flex my-[30px] h-[80px] justify-between">
          <div className="flex gap-[5px] flex-wrap">
            {expiryDate(
              currentApiKey?.expiryDate,
              currentApiKey.creationDate
            ) <= 0 ? (
              <div className="flex gap-[5px] flex-wrap">
                <APIKEY_LABEL>Your trial has ended.</APIKEY_LABEL>{" "}
                <Text16px>
                  {" "}
                  Upgrade now to regain access and receive more test queries.
                </Text16px>
              </div>
            ) : (
              <div className="flex gap-[5px] flex-wrap">
                <APIKEY_LABEL>Your trial is ending soon.</APIKEY_LABEL>{" "}
                <Text16px>
                  {" "}
                  You have{" "}
                  {expiryDate(
                    currentApiKey?.expiryDate,
                    currentApiKey.creationDate
                  )}{" "}
                  days remaining on Trial
                </Text16px>
              </div>
            )}
          </div>

          <TrestleButton
            onClick={() => {
              navigate(RouteConstants.ROUTE_PRICING_PAGE);
              handleMixPanel(MixPanelEvents.WALLET_UPGRADE_NOW_BANNER, {
                userEmail: userData?.email,
              });
            }}
            label="Upgrade Now"
            type="primary"
            className="!w-fit !h-[50px]"
          />
        </div>
      );
    }

    if (currentSelectedProduct?.status === "CAN_REQUEST") {
      return (
        <>
          <div className="flex gap-[10px] items-center">
            <div className="flex gap-[5px] flex-wrap">
              <APIKEY_LABEL>
                You do not have access to this product yet.
              </APIKEY_LABEL>
              <Text16px>Try it out with a sample request below.</Text16px>
            </div>
          </div>

          <div className="w-[200px]">
            <TrestleButton
              onClick={() => {
                RequestAccess(currentSelectedProduct);
              }}
              label="Request Access"
              type="primary"
              className="!h-[40px] !leading-[40px] !p-0"
            />
          </div>
        </>
      );
    } else if (
      currentSelectedProduct?.status === "UNAVAILABLE" ||
      currentSelectedProduct?.status === "NOT_IN_USE" ||
      currentSelectedProduct?.status === "REMOVE"
    ) {
      return (
        <>
          <div className="flex gap-[10px] items-center">
            <div className="flex gap-[5px] flex-wrap">
              <APIKEY_LABEL>
                You do not have access to this product.
              </APIKEY_LABEL>{" "}
              <Text16px>Try it out with a sample request below.</Text16px>
            </div>
          </div>
        </>
      );
    } else if (currentSelectedProduct?.status === "REQUESTED") {
      return (
        <>
          <div className="flex gap-[10px] items-center">
            <div className="flex gap-[5px] flex-wrap">
              <APIKEY_LABEL>
                Your request for this product is pending.
              </APIKEY_LABEL>
            </div>
          </div>
        </>
      );
    } else if (currentSelectedProduct?.status === "DENIED") {
      return (
        <>
          <div className="flex gap-[10px] items-center">
            <div className="flex gap-[5px] flex-wrap">
              <APIKEY_LABEL>
                Your request for this product has been denied.
              </APIKEY_LABEL>{" "}
              <Box className="flex gap-[5px]">
                <Text16px>Reach out to</Text16px>
                <ActionButtonContainer
                  onClick={() =>
                    window.open("mailto:support@trestleiq.com", "_parent")
                  }
                  className="!underline"
                >
                  support@trestleiq.com
                </ActionButtonContainer>
                <Text16px>if you have any questions or requests.</Text16px>
              </Box>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const showBanner = () => {
    if (
      currentSelectedProduct?.status === "CAN_REQUEST" ||
      currentSelectedProduct?.status === "UNAVAILABLE" ||
      currentSelectedProduct?.status === "REMOVE" ||
      currentSelectedProduct?.status === "NOT_IN_USE" ||
      currentSelectedProduct?.status === "REQUESTED" ||
      currentSelectedProduct?.status === "DENIED" ||
      currentApiKey?.planType === "TRIAL"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box>
      {showBanner() && (
        <Box className="mb-10">
          <BannerComponentDiv>{getBannerComponentData()}</BannerComponentDiv>
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          spacing={4}
          sx={{ px: "48px", pb: "50px", maxWidth: "2200px" }}
        >
          <LoaderComponent loader={isLoading} />
          {/* <Grid item md={3}>
          <Box
            onClick={() => {
              navigate("/api-keys");
            }}
            sx={{
              display: "flex",
              gap: "10px",
              color: "#0494B4",
              textDecoration: "underline",
              alignItems: "center",
              cursor: "pointer",
              px: "5px",
              fontWeight: "700",
            }}
          >
            {BackwardArrow}Back to APIs home
          </Box>
          <Stack direction="column" spacing={2} mt={"30px"}>
            {AppConstants.API_KEY_PRODUCT_LIST.map((item) => {
              return (
                <Stack direction={"column"} spacing={2}>
                  <Text20pxBold c="#163545">{item.heading}</Text20pxBold>
                  <Stack spacing={2}>
                    {item.products.map((product) => {
                      return (
                        <Box
                          onClick={() => {
                            if (product.name === currentApiProduct) {
                              return;
                            }
                            dispatch(setCurrentApiProduct(product.name));
                            setStatus("Ready");
                          }}
                          sx={{
                            width: "100%",
                            height: "100%",
                            background: "#FFFFFF",
                            display: "flex",
                            flexDirection: "column",
                            cursor: "pointer",
                            padding: "14px 10px",
                            gap: "15px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "14px",
                                  width: "14px",
                                  background: `${product.iconBoxColor}`,
                                }}
                              />
                              <Text16pxBold c="#163545">
                                {product.name}
                              </Text16pxBold>
                            </Box>
                            {product.name !== currentApiProduct && (
                              <Box className="c-[#163545]">{ForwardArrow}</Box>
                            )}
                          </Box>
                          {product.name === currentApiProduct && (
                            <Stack ml={"50px"} spacing={2}>
                              <Text16pxBold
                                c="#163545"
                                onClick={() => navigate("/usage")}
                              >
                                Usage
                              </Text16pxBold>
                              <Text16pxBold
                                c="#163545"
                                className="flex items-center justify-between c-[#163545] pr-[10px]"
                                onClick={() =>
                                  window.open("https://trestleiq.com/partners/")
                                }
                              >
                                Partnerships{" "}
                                <Box className="c-[#163545]">{LinkIcon}</Box>
                              </Text16pxBold>
                              {product.name !== "Address Validation API" && (
                                <Text16pxBold
                                  c="#163545"
                                  className="flex items-center justify-between c-[#163545] pr-[10px]"
                                  onClick={() =>
                                    window.open(
                                      `https://trestle-api.redoc.ly/Current/tag/${product.name.replace(
                                        /\s+/g,
                                        "-"
                                      )}`
                                    )
                                  }
                                >
                                  Documentation
                                  <Box className="c-[#163545]">{LinkIcon}</Box>
                                </Text16pxBold>
                              )}

                              <Text16pxBold
                                c="#163545"
                                onClick={() => navigate("/pricing")}
                              >
                                Pricing
                              </Text16pxBold>
                            </Stack>
                          )}
                        </Box>
                      );
                    })}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Grid> */}
          <Grid item md={12}>
            <Box className="flex flex-col gap-[38px]">
              <Box
                onClick={() => {
                  navigate("/api-keys");
                }}
                sx={{
                  display: "flex",
                  gap: "10px",
                  color: "#0494B4",
                  textDecoration: "underline",
                  alignItems: "center",
                  cursor: "pointer",
                  px: "5px",
                  fontWeight: "700",
                }}
              >
                {BackwardArrow}Back to APIs home
              </Box>
              <Box className="flex justify-between items-center">
                <Box className="flex gap-[20px] items-center">
                  <Box className="flex items-center gap-[16px] w-fit">
                    <ComponentLabelHeader className="w-full">
                      {currentApiProduct}
                    </ComponentLabelHeader>
                    <Box
                      component={"img"}
                      src={PhoneLogo}
                      alt={"phone-logo"}
                    ></Box>
                  </Box>
                  <Box className="w-[200px]">
                    <DropDown
                      name={"version"}
                      label={""}
                      value={version}
                      error={""}
                      placeholder={"Search"}
                      onChange={(val: any) => {
                        handleVersionChange(val);
                      }}
                      required={false}
                      options={versionArray}
                      disabled={versionArray?.length <= 1}
                      className="!h-[37px] text-[#163545] bg-white !font-bold"
                    />
                  </Box>
                  <Box>
                    {currentSelectedProduct?.status === "REQUESTED" && (
                      <Box className="text-[#163545] flex gap-[7px] items-center">
                        {ClockIcon}Access Pending
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box className="flex items-center gap-[5px] cursor-default">
                  <Text16pxMedium>Ready to Talk?</Text16pxMedium>
                  <ActionButtonContainer
                    onClick={() =>
                      window.open("https://trestleiq.com/contact/")
                    }
                    className="!text-[16px] !underline !decoration-solid"
                  >
                    Contact Sales
                  </ActionButtonContainer>
                </Box>
              </Box>
              {/* {showBanner() && (
              <BannerComponentDiv className="!mt-[5px]">
                {getBannerComponentData()}
              </BannerComponentDiv>
            )} */}

              <Box>
                {currentSelectedProduct && (
                  <NewTestRequestForm
                    currentSelectedProduct={currentSelectedProduct}
                    activeApiKey={currentApiKey?.apiKey}
                    setIsLoading={setIsLoading}
                    usageLimit={currentApiKey.limit}
                    setResponse={setResponse}
                    setStatus={setStatus}
                    setInitialResponseData={setInitialResponseData}
                  />
                )}
              </Box>
              <Box className="bg-white p-[30px]">
                <Box className="flex flex-col gap-[30px]">
                  <Text20pxBold c="#163545">Response</Text20pxBold>
                  <Grid container spacing={4}>
                    <Grid item md={8}>
                      <JsonDisplayComponent
                        containerWidth="100%"
                        width="100%"
                        height="fit-content"
                        data={response}
                        status={status}
                      />
                    </Grid>

                    <Grid item md={4}>
                      {!_.isEmpty(apiKeyResponseDetails) && (
                        <Box sx={{ background: "#F5F5F5", height: "100%" }}>
                          {apiKeyResponseDetails?.map(
                            (item: any, index: any) => {
                              return (
                                <ApiProductResponseKeyListComponent
                                  expanded={expanded}
                                  setExpanded={(val: any) => {
                                    if (expanded !== `panel${index}`) {
                                      setExpanded(`panel${index}`);
                                    } else if (val === "close") {
                                      setExpanded(val);
                                    }
                                  }}
                                  accordionStyles={{
                                    background: "#F5F5F5",
                                    boxShadow: "none",
                                  }}
                                  accordionSummaryStyles={{
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    lineHight: "17px",
                                    color: "#00485A",
                                  }}
                                  accordionDetailsStyles={{
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    color: "#163545",
                                  }}
                                  summary={item.heading}
                                  details={item.subheading}
                                  index={index}
                                  disabled={false}
                                />
                              );
                            }
                          )}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className="bg-white p-[30px]">
                <Box className="flex items-center justify-between">
                  <Text20pxBold c="#163545">Usage</Text20pxBold>
                  <Box className="flex gap-[5px] items-center">
                    <Text16pxMedium>Queries used:</Text16pxMedium>
                    <Text16pxBold>
                      {currentApiKey?.limit === 0
                        ? currentSelectedProduct?.usageCountV2
                        : `${currentSelectedProduct?.usageCountV2}/${currentApiKey.limit}`}
                    </Text16pxBold>
                  </Box>
                </Box>
                <YourUsage
                  apiKeyId={currentApiKey?.apiKeyId}
                  productId={currentSelectedProduct?.productId}
                />
              </Box>
              <Box className="bg-white p-[30px] flex flex-col gap-[30px]">
                <Box className="flex items-center justify-between">
                  <Text20pxBold c="#163545">API Integration</Text20pxBold>
                  <Box className="flex gap-[5px] items-center">
                    <Text16pxBold
                      c="#0494B4"
                      className="flex items-center cursor-pointer gap-[10px] underline !font-bold pr-[10px]"
                      onClick={() =>
                        window.open(
                          `https://trestle-api.redoc.ly/Current/tag/${currentApiProduct?.replace(
                            /\s+/g,
                            "-"
                          )}`
                        )
                      }
                    >
                      Documentation
                      <Box className="c-[#163545]">{LinkIcon}</Box>
                    </Text16pxBold>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="flex flex-col gap-[10px]">
                    <Text16pxBold>API Key</Text16pxBold>
                    <InputTextField
                      name={"apikey"}
                      label={""}
                      value={{ apikey: currentApiKey?.apiKey }}
                      error={{ apikey: "" }}
                      type={"text"}
                      onChange={() => {}}
                      disabled={true}
                      copyToClipboard={true}
                      className="!w-[670px] !pr-0"
                      disabledStyle="!c-[#163545]"
                      inputStyle={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          "-webkit-text-fill-color": "#163545 !important",
                        },
                      }}
                      endAdornmentStyle={{
                        background: "rgb(229, 229, 229)",
                        color: "#555555",
                        height: "60px",
                        pl: "15px",
                        maxHeight: "60px",
                        margin: "0px",
                        justifyContent: "center",
                      }}
                    />
                  </div>
                </Box>
                <Divider />
                <Box className="flex flex-col gap-[20px]">
                  <Text16pxBold c="#163545">Ho to Use This API</Text16pxBold>
                  <Box className="flex flex-col gap-[10px]">
                    <Text14pxBold>Headers</Text14pxBold>
                    <Box className="text-[#163545]">
                      'accept: application/json'
                      <br />
                      {`'x-api-key: ${"{insert_api_key}"}'`}
                    </Box>
                    <Text14pxBold>URL</Text14pxBold>
                    <Box className="text-[#163545]">{requestUrl}</Box>
                  </Box>
                </Box>
              </Box>
              {walletData?.walletDisplayType !== "UNLIMITED" && (
                <Box className="bg-white p-[30px] flex flex-col gap-[30px]">
                  <Box className="flex items-center justify-between">
                    <Text20pxBold c="#163545">Pricing</Text20pxBold>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: "#F5F5F5",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "14px 10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box sx={{ width: "16px", height: "16px" }}>
                        {TrestleIconSmall}
                      </Box>
                      <Text14pxBold c="#163545">
                        {currentApiProduct}
                      </Text14pxBold>
                    </Box>
                    <Box
                      display={"flex"}
                      sx={{ alignItems: "baseline", gap: "5px" }}
                    >
                      <Text14pxBold c="#163545">{`$${currentSelectedProduct?.pricing}`}</Text14pxBold>
                      <Text14pxBold>/query</Text14pxBold>
                    </Box>
                  </Box>
                  <Box className="flex gap-[20px]">
                    <TrestleButton
                      type="primary"
                      onClick={() => navigate("/pricing")}
                      label={
                        walletData?.walletDisplayType === "TRIAL" ||
                        walletData?.walletDisplayType === "TRIAL_EXPIRED"
                          ? "Upgrade to self serve"
                          : "Add to Self-serve Wallet"
                      }
                      className="!w-[290px] !h-[50px]"
                    />
                    <TrestleButton
                      type="new_contact"
                      onClick={() =>
                        window.open("https://trestleiq.com/contact/")
                      }
                      label="Contact Sales for Enterprise"
                      className="!w-[290px] !h-[50px]"
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ApiKeyProductDetailsPage;
