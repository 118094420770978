import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import LoaderComponent from "../../Shared/LoaderComponent";
import { ComponentLabelHeader } from "../../Shared/CommonComponents";
import DropDown from "../../Shared/DropDown";
import {
  GetAdminUsage,
  callCustomerUsageReport,
  callExportCsvAPIChartOne,
  callExportMasterReportCSV,
  getAllProducts,
  getProductsAndAPIkeys,
  getUsageData,
  callCustomerUsageReportV2,
} from "../../../http/subscription-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import DateRangeTrestle from "../../Shared/DateRangePicker";
import TrestleButton from "../../Shared/TrestleButton";
import moment from "moment";
import { useAppSelector } from "../../../app/hooks";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import TrestleTable, {
  TableHeaderProps,
} from "../../Shared/TrestleTable/TrestleTable";
import UserDrawer from "../UserDrawer/UserDrawer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const,
    },
  },
};

const datasetsColors = [
  {
    borderColor: "#254b87",
    backgroundColor: "#254b87",
  },
  {
    borderColor: "#addc91",
    backgroundColor: "#addc91",
  },
  {
    borderColor: "#9daecc",
    backgroundColor: "#9daecc",
  },
  {
    borderColor: "#257675",
    backgroundColor: "#257675",
  },
  {
    borderColor: "#89b6b5",
    backgroundColor: "#89b6b5",
  },
  {
    borderColor: "#0072ce",
    backgroundColor: "#0072ce",
  },
  {
    borderColor: "#7eb7e8",
    backgroundColor: "#7eb7e8",
  },
  {
    borderColor: "#1fa040",
    backgroundColor: "#1fa040",
  },
];

interface YourUsageProps {
  userId?: string | number;
}

function CustomerUsageV2({ userId = 0 }: YourUsageProps) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [plotFilterData, setPlotFilterData] = useState<any>({
    products: ["All Products"],
    apikeys: [],
    timeline: "Daily",
    timeframe: "Today",
    plantype: "ALL",
  });

  const [dateRage, setDateRage] = useState<any>({
    startTime: new Date(),
    endTime: new Date(),
  });

  const [timeframeOptions, setTimeframeOptions] = useState<any>([
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Custom range",
  ]);

  const numberDays: any = {
    Today: 0,
    Yesterday: 2,
    "Last 7 days": 7,
    "Last 30 days": 30,
    "Custom range": null,
  };

  const [usageData, setUsageData] = useState<any>([]);

  useEffect(() => {
    const init = async () => {
      await ExportUsageChartData();
    };

    // init();  // As per TRES-961 stop this API call on load of page inorder to get usage user need to click Get Usage mannually.
  }, []);

  const ExportUsageChartData = async () => {
    setIsLoading(true);
    const payload = getPayload();
    const response = await callCustomerUsageReportV2(payload);
    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setIsLoading(false);
      // let temp: any = data.data.map((item: any, intex: number) => {
      //   item["srNo"] = intex + 1;
      //   return item;
      // });
      setUsageData(data.data);
    } else {
      setIsLoading(false);

      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.message,
        })
      );
    }
  };

  const getPayload = () => {
    const { timeframe } = plotFilterData;
    const { plantype } = plotFilterData;

    let startEndData: any = LastNthDays(timeframe);

    let payload = {
      userIds: [],
      productIds: [],
      apiKeyIds: [],
      planTypes: plantype === "ALL" ? [] : [plantype],
      ...startEndData,
    };

    return payload;
  };

  const differenceInMonths = (date1: any, date2: any) => {
    const monthDiff = date1.getMonth() - date2.getMonth();
    const yearDiff = date1.getYear() - date2.getYear();

    return monthDiff + yearDiff * 12;
  };

  const LastNthDays = (timeframeO: string) => {
    let lastDays: number = numberDays[timeframeO];

    if (lastDays !== null) {
      let result = [];
      for (let i = 0; i < lastDays; i++) {
        let d = new Date();
        d.setDate(d.getDate() - i);
        result.push(d);
      }

      return {
        startTime: dateFormat(result[result.length - 1]),
        endTime:
          timeframeO === "Yesterday"
            ? dateFormat(result[result.length - 1])
            : dateFormat(result[0]),
      };
    } else {
      const { startTime, endTime } = dateRage;

      return {
        startTime: dateFormat(startTime),
        endTime: dateFormat(endTime),
      };
    }
  };

  const dateFormat = (date: any) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const headCells: readonly TableHeaderProps[] = [
    {
      id: "id",
      numeric: true,
      disablePadding: false,
      label: "User ID",
      width: "8%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return <UserDrawer userName={`${ele.name || "-"}`} userData={ele} />;
      },
      sortable: true,
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      label: "Company",
      width: "13%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return ele?.company;
      },
      sortable: true,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      width: "15%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "apiKey",
      numeric: false,
      disablePadding: false,
      label: "API KEY",
      width: "12%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "plan",
      numeric: false,
      disablePadding: false,
      label: "Plan",
      width: "10%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "product",
      numeric: false,
      disablePadding: false,
      label: "Product",
      width: "20%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "usageCount",
      numeric: true,
      disablePadding: false,
      label: "Usage Count",
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return <div className="w-full text-center pr-7">{ele.usageCount}</div>;
      },
      sortable: true,
    },
  ];

  return (
    <>
      <LoaderComponent
        loader={isLoading}
        msg={ConstantTexts.LONG_TIME_LOADING_TEXT}
      />
      {
        <>
          <div
            className={`bg-white min-h-full ${
              userId ? "w-full" : "p-[45px] w-[90%]"
            } !mb-12`}
          >
            {/* <div className="text-base font-normal mb-10 text-[#6A6B6B]">
              Generate a CSV report for all products per user and per API key
              over your defined timeframe. Once completed, the report will be
              emailed to you.
            </div> */}

            {!userId && (
              <div className="flex w-full mb-[60px]">
                <div className="max-w-[280px] w-full mr-2">
                  <DropDown
                    name={"timeframe"}
                    label={"Timeframe"}
                    value={plotFilterData.timeframe}
                    error={""}
                    placeholder={"Search"}
                    onChange={(val: any) => {
                      const { timeframe, timeline } = plotFilterData;
                      setPlotFilterData({
                        ...plotFilterData,
                        timeframe: val,
                        timeline:
                          timeframe === "Custom range" ? timeline : "Daily",
                      });
                    }}
                    required={false}
                    options={timeframeOptions}
                    className="!h-[50px] text-[#163545]"
                  />
                </div>

                {plotFilterData.timeframe === "Custom range" && (
                  <div className="max-w-[280px] w-full mr-2">
                    <DateRangeTrestle
                      value={dateRage}
                      setValue={(val: any) => {
                        let timelineVal = "Daily";

                        if (plotFilterData.timeframe === "Custom range") {
                          let temp = differenceInMonths(
                            val.endDate,
                            val.startDate
                          );

                          if (temp > 1) {
                            timelineVal = "Monthly";
                          }
                        }

                        setPlotFilterData({
                          ...plotFilterData,
                          timeline: timelineVal,
                        });

                        setDateRage({
                          startTime: val.startDate,
                          endTime: val.endDate,
                        });
                      }}
                    />
                  </div>
                )}

                <div className="max-w-[280px] w-full mr-2">
                  <DropDown
                    name={"plantype"}
                    label={"Plan Type"}
                    value={plotFilterData.plantype}
                    error={""}
                    placeholder={"Search"}
                    onChange={(val: any) => {
                      const { timeframe, timeline } = plotFilterData;
                      setPlotFilterData({
                        ...plotFilterData,
                        plantype: val,
                      });
                    }}
                    required={false}
                    options={["ALL", "ENTERPRISE", "TRIAL", "SELF_SERVE"]}
                    className="!h-[50px] text-[#163545]"
                  />
                </div>

                <TrestleButton
                  onClick={() => {
                    ExportUsageChartData();
                  }}
                  label="Get Usage"
                  type="primary"
                  className="!h-[50px] !leading-[50px] !p-0 !min-w-[150px] !max-w-[150px] mt-auto ml-4"
                />
              </div>
            )}

            <div>
              <TrestleTable
                isPaginated={false}
                headCells={headCells}
                rows={[...usageData]}
                clientSort={true}
                defaultOrder={{ order: "desc", orderBy: "usageCount" }}
              />
            </div>
          </div>
        </>
      }
    </>
  );
}

export default CustomerUsageV2;
